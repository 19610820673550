import React, { useState } from "react";
import PageContainer from "components/commons/PageContainer";
import { capitalizeFirstLetter, lowerCaseAllAndcapitalizeFirstLetter } from "utils/strings";
import styles from "./styles/index.scss";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import FormTextInput from "components/commons/FormFields/FormTextInput";
import { useForm } from "react-hook-form";
import FormTextareaInput from "components/commons/FormFields/FormTextareaInput";
import Button from "@material-ui/core/Button";
import FormSelectInput from "components/commons/FormFields/FormSelectInput";
import isEmail from "validator/lib/isEmail";
import PageFooter from "components/PageFooter";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useNavigate } from "react-router-dom";
import ConfirmationPage from "./ConfirmationPage";
import useContactApi from "api/hooks/useContactApi";
import isMobilePhone from "validator/lib/isMobilePhone";
import { toast } from "react-toastify";
import { useAnalyticsPage, analyticsTrack } from "hooks/useAnalytics";
const PublicContactPage = () => {
    useAnalyticsPage("CONTACT_PAGE");
    const { t } = useTranslation();
    const navigate = useNavigate();
    const contactApi = useContactApi();
    const [isSent, setIsSent] = useState(false);
    const { register, handleSubmit, errors, reset } = useForm({
        mode: "onChange",
        reValidateMode: "onChange"
    });
    const onSubmit = async (data) => {
        const { firstname, lastname, email, message, objectOfMessage, phoneNumber } = data;
        analyticsTrack("CONTACT_SUBMIT_FORM", data);
        try {
            await contactApi.createContact({
                firstname,
                lastname,
                email,
                message,
                objectOfMessage,
                documents: [],
                phoneNumber,
                destination: ""
            });
            toast.success(capitalizeFirstLetter(t("success.success_send_message_contact")));
            analyticsTrack("CONTACT_SUBMIT_FORM_SUCCESS", data);
            reset(data);
            setIsSent(true);
        }
        catch {
            toast.error(capitalizeFirstLetter(t("errors.fail_to_send_message_contact")));
            analyticsTrack("CONTACT_SUBMIT_FORM_FAIL", data);
        }
    };
    const handleClose = () => {
        navigate(-1);
    };
    const fieldsName = {
        firstname: "firstname",
        lastname: "lastname",
        email: "email",
        objectOfMessage: "objectOfMessage",
        message: "message",
        documents: "documents",
        phoneNumber: "phoneNumber"
    };
    const questions = ["platform", "terms", "personal_information", "other"];
    if (isSent) {
        return React.createElement(ConfirmationPage, null);
    }
    return (React.createElement(PageContainer, { fullwidth: false, footer: PageFooter },
        React.createElement("div", { className: styles.root },
            React.createElement("div", { className: styles.content },
                React.createElement("div", { className: styles.title },
                    capitalizeFirstLetter(t("contact.title")),
                    React.createElement(IconButton, { className: styles.closeButton, onClick: handleClose },
                        React.createElement(CloseIcon, null))),
                React.createElement("div", { className: styles.form },
                    React.createElement(Grid, { container: true, spacing: 6 },
                        React.createElement(Grid, { container: true, item: true, xs: 12, spacing: 3 },
                            React.createElement(FormTextInput, { label: t("contact.firstname"), errors: errors, fieldName: fieldsName.firstname, formRef: register({
                                    required: t("contact.error.firstname")
                                }) })),
                        React.createElement(Grid, { container: true, item: true, xs: 12, spacing: 3 },
                            React.createElement(FormTextInput, { label: t("contact.lastname"), fieldName: fieldsName.lastname, errors: errors, formRef: register({
                                    required: t("contact.error.lastname")
                                }) })),
                        React.createElement(Grid, { container: true, item: true, xs: 12, spacing: 3 },
                            React.createElement(FormTextInput, { label: t("contact.email"), fieldName: fieldsName.email, errors: errors, formRef: register({
                                    validate: value => isEmail(value) || t("contact.error.email")
                                }) })),
                        React.createElement(Grid, { container: true, item: true, xs: 12, spacing: 3 },
                            React.createElement(FormTextInput, { label: t("contact.phoneNumber"), fieldName: fieldsName.phoneNumber, errors: errors, formRef: register({
                                    validate: value => isMobilePhone(value) ||
                                        t("contact.error.phoneNumber")
                                }) })),
                        React.createElement(Grid, { container: true, item: true, xs: 12, spacing: 3 },
                            React.createElement(FormSelectInput, { label: t("contact.object_of_message"), fieldName: fieldsName.objectOfMessage, placeholder: t("contact.select_object_of_message"), errors: errors, values: questions.map((question) => ({
                                    value: question,
                                    label: lowerCaseAllAndcapitalizeFirstLetter(t(`contact.question.${question}`, question))
                                })), formRef: register({
                                    required: t("contact.error.object_of_message")
                                }) })),
                        React.createElement(Grid, { container: true, item: true, xs: 12, spacing: 3 },
                            React.createElement(FormTextareaInput, { label: t("contact.message"), fieldName: fieldsName.message, errors: errors, formRef: register({
                                    required: t("contact.error.message")
                                }) })),
                        React.createElement(Grid, { container: true, item: true, xs: 12, spacing: 3 },
                            React.createElement("div", { className: styles.formBottom },
                                React.createElement(Button, { onClick: handleSubmit(onSubmit), className: styles.formBottom__submitBtn }, t("contact.submit").toUpperCase())))))))));
};
export default PublicContactPage;
