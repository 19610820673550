import * as isomorphicFetch from "isomorphic-fetch";
export class BaseAPI {
    basePath;
    fetch;
    constructor(configuration, basePath = "", fetch = isomorphicFetch) {
        this.basePath = basePath;
        this.fetch = fetch;
        if (configuration) {
            this.basePath = configuration.basePath || this.basePath;
        }
        this.fetch = fetch;
    }
}
