import React, { useState } from "react";
import { Auth } from "aws-amplify";
import CancelButton from "./CancelButton";
import { useTranslation } from "react-i18next";
import PasswordInput from "../../commons/PasswordInput";
import styles from "./styles/ForgotPasswdScreen.scss";
import checkPassword from "react-cognito-authentifier/checkPassword";
import { InvalidPasswordMessage } from "./ChangePasswdScreen";
const ForgotPasswdScreen = ({ onStateChange, authData, config, }) => {
    const { t } = useTranslation();
    const [username, setUsername] = useState(authData.username);
    const [verificationCode, setVerificationCode] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [error, setError] = useState("");
    const passwordMatch = password === passwordConfirmation;
    const forgotPasswordSubmit = async (event) => {
        setError("");
        event.preventDefault();
        if (username) {
            try {
                await Auth.forgotPasswordSubmit(username, verificationCode, password);
            }
            catch (err) {
                setError(err.message);
            }
        }
    };
    const onUsernameChange = (evt) => {
        setUsername(evt.target.value);
    };
    const onPasswordChange = (evt) => {
        setPassword(evt.target.value);
    };
    const onPasswordConfirmationChange = (evt) => {
        setPasswordConfirmation(evt.target.value);
    };
    const onVerificationCodeChange = (evt) => {
        setVerificationCode(evt.target.value);
    };
    const onCancelClick = () => {
        onStateChange("signIn", {});
    };
    const passwordIsValid = checkPassword(password, config?.passwordPolicy) === null;
    return (React.createElement("form", { className: styles.formContainer, onSubmit: forgotPasswordSubmit },
        React.createElement(CancelButton, { style: { alignSelf: "flex-end" }, onClick: onCancelClick }),
        React.createElement("div", { className: styles.title }, t("forgot_password.title")),
        error && (React.createElement("div", { className: styles.errorContainer }, t("forgot_password.fail", { error }))),
        !authData.username && (React.createElement(React.Fragment, null,
            React.createElement("label", { className: styles.label, htmlFor: "username" }, t("forgot_password.username")),
            React.createElement("input", { className: styles.input, onChange: onUsernameChange, type: "text", id: "username", key: "username", name: "username", placeholder: "" }))),
        React.createElement("label", { className: styles.label, htmlFor: "new_password" }, t("forgot_password.new_password")),
        React.createElement(PasswordInput, { onChange: onPasswordChange, name: "new_password" }),
        !!password && !passwordIsValid && (React.createElement(InvalidPasswordMessage, { policy: config?.passwordPolicy })),
        React.createElement("label", { className: styles.label, htmlFor: "password_confirmation" }, t("forgot_password.password_confirmation")),
        React.createElement(PasswordInput, { onChange: onPasswordConfirmationChange, name: "password_confirmation", error: !passwordMatch }),
        React.createElement("label", { className: styles.label, htmlFor: "verification_code" }, t("forgot_password.verification_code")),
        React.createElement("input", { className: styles.input, onChange: onVerificationCodeChange, type: "text", id: "verification_code", key: "verification_code", name: "verification_code", placeholder: "" }),
        React.createElement("p", null),
        React.createElement("button", { className: styles.button, disabled: !username ||
                !password ||
                !passwordConfirmation ||
                !verificationCode ||
                !passwordMatch ||
                !passwordIsValid }, t("forgot_password.change_password"))));
};
export default ForgotPasswdScreen;
