const useAppConfiguration = () => {
    return {
        displayPublicContractPage: false,
        // farmer general infromation
        displayFarmerUploadedDocumentSection: false,
        displayFarmerIban: false,
        displayFarmerCity: false,
        displayFarmerZipCode: false,
        displayFarmerAddress: false,
        displayFarmerPhoneNumber: false
    };
};
export default useAppConfiguration;
