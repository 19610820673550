export default class QueryKeys {
    fetchInsuredCompanies = "fetchInsuredCompanies";
    getContract = "getContract";
    fetchContracts = "fetchContracts";
    fetchContractsByEndCustomerId = "fetchContracts by end customer id";
    fetchStatisticsInContractMap = "fetch statistics in contract map";
    getGeojson = "fetch geojson";
    getEndCustomer = "getEndCustomer";
    getEndCustomerByUserId = "getEndCustomerByUserId";
    getPromoter = "getPromoter";
    getPromoterByUserId = "getPromoterByUserId";
    getManager = "getManager";
    getFarmerAddress = "getFarmerAddress";
    getEndCustomerDocuments = "getEndCustomerDocuments";
    getPurchasedProducts = "getPurchasedProducts";
    searchPromoterName = "searchPromoterName";
    searchEndcustomerName = "searchEndcustomerName";
    searchEndcustomerEmail = "searchEndcustomerEmail";
    searchEndcustomerPhoneNumber = "searchEndcustomerPhoneNumber";
    searchEndcustomerCity = "searchEndcustomerCity";
    searchEndcustomerCounty = "searchEndcustomerCounty";
    searchAreaName = "searchAreaName";
    searchEndcustomerIsRegistered = "searchEndcustomerIsRegistered";
    searchHasReplowDeclaration = "searchHasReplowDeclaration";
    getDashBoardTableEndcustomersCount = "getDashBoardTableEndcustomersCount";
    getInsuredCompany = "getInsuredCompany";
    getSeedVarietiesByContractId = "getSeedVarietiesByContractId";
    getSeedVarietiesByEndCustomerId = "getSeedVarietiesByEndCustomerId";
    fetchStorageCompanies = "fetchStorageCompanies";
    getSeedVarietiesByStorageCompanyId = "getSeedVarietiesByStorageCompanyId";
}
