import React from "react";
import FieldErrorMessage from "../FieldErrorMessage";
import styles from "./styles/formSelectInput.scss";
import classNames from "classnames";
const FormSelectInput = ({ label, fieldName, errors, values, formRef, onChange, placeholder, disabled = false, defaultValue, inputClassName, isClearable = false, }) => {
    return (React.createElement("div", { className: styles.root },
        label && (React.createElement("label", { className: styles.root__label, htmlFor: fieldName }, label)),
        React.createElement("select", { name: fieldName, defaultValue: placeholder ? "" : defaultValue, ref: formRef, onChange: onChange, className: classNames(styles.formInput, inputClassName), disabled: disabled },
            placeholder && (React.createElement("option", { disabled: !isClearable, value: "" }, placeholder)),
            values.map((item) => (React.createElement("option", { key: item.value, value: item.value, className: styles.formSelectItem }, item.label)))),
        React.createElement(FieldErrorMessage, { errors: errors, name: fieldName })));
};
export default FormSelectInput;
