import React, { useState, useRef, useEffect, useCallback } from "react";
import styles from "./styles/index.module.scss";
import { getUserFullName } from "../utils/utils";
import { useUserInfo } from "../hooks/useUserInfo";
import Loader from "./Loader";
import UserDropDownItem from "./UserDropDownItem";
import { useAuth } from "../AuthProvider";
import LogoutIcon from "./assets/logout.svg";
import MenuCloserContext from "./MenuCloserContext";
const UserDropDown = ({ children, displayLogout = true, logoutText, onLogout, }) => {
    const user = useUserInfo();
    const auth = useAuth();
    const [showMenu, setShowMenu] = useState(false);
    const menuToggleRef = useRef(null);
    const menuRef = useRef(null);
    useEffect(() => {
        const onMouseDown = (event) => {
            const target = event.target;
            if (menuToggleRef.current && menuToggleRef.current.contains(target)) {
                setShowMenu((v) => !v);
            }
            else if (menuRef.current && menuRef.current.contains(target)) {
                // Do nothing
            }
            else {
                setShowMenu(false);
            }
        };
        document.addEventListener("mousedown", onMouseDown);
        return () => {
            document.removeEventListener("mousedown", onMouseDown);
        };
    }, []);
    const closeMenu = useCallback(() => {
        setShowMenu(false);
    }, []);
    if (!user) {
        return React.createElement(Loader, null);
    }
    const handleLogoutClick = async () => {
        await auth.signOut();
        if (onLogout) {
            onLogout();
        }
    };
    const avatarValue = user
        ? `${user.first_name ? user.first_name.slice(0, 1).toUpperCase() : ""}${user.last_name ? user.last_name.slice(0, 1).toUpperCase() : ""}`
        : "";
    return (React.createElement("div", { className: styles.root },
        React.createElement("div", { ref: menuToggleRef, className: styles.container },
            React.createElement("div", { className: styles.avatar }, avatarValue),
            React.createElement("div", { className: styles.text }, getUserFullName(user)),
            React.createElement("i", { className: styles.down })),
        showMenu && (React.createElement("div", { ref: menuRef, className: styles.dropdownContent },
            React.createElement("div", { className: styles.userInfoContainer },
                React.createElement("div", { className: styles.avatarBig }, avatarValue),
                React.createElement("div", { className: styles.textBig }, getUserFullName(user)),
                React.createElement("div", { className: styles.subTextBig }, user ? user.email : "")),
            React.createElement("div", { className: styles.divider }),
            React.createElement("div", { className: styles.itemsPlaceholder },
                React.createElement(MenuCloserContext.Provider, { value: closeMenu },
                    children,
                    displayLogout && (React.createElement(UserDropDownItem, { icon: LogoutIcon, text: logoutText || "Logout", onClick: handleLogoutClick })))),
            React.createElement("div", { className: styles.divider })))));
};
export default UserDropDown;
export { default as UserDropDownItem } from "./UserDropDownItem";
