/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from "react";
import UserManager from "./UserManager";
import { useUserProfile } from "react-cognito-authentifier";
import useEndCustomersApi from "api/hooks/useEndCustomerApi";
import usePromoterApi from "api/hooks/usePromoterApi";
import useManagerApi from "api/hooks/useManagerApi";
export const AppContext = React.createContext(undefined);
export const useUserManager = () => {
    const [userManager, setUserManager] = useState();
    const { initialUserInfo } = useUserProfile();
    const endCustomerApi = useEndCustomersApi();
    const promoterApi = usePromoterApi();
    const managerApi = useManagerApi();
    useEffect(() => {
        (async () => {
            try {
                const um = new UserManager(initialUserInfo);
                if (um.isFarmer()) {
                    const endCustomer = await endCustomerApi.getEndCustomerByUserId(initialUserInfo?.id);
                    if (!endCustomer) {
                        setUserManager("error");
                        return;
                    }
                    um.setEndCustomer(endCustomer);
                }
                else if (um.isPromoter()) {
                    const promoter = await promoterApi.getPromoterByUserId(initialUserInfo?.id);
                    if (!promoter) {
                        setUserManager("error");
                        return;
                    }
                    um.setPromoter(promoter);
                }
                else if (um.isMarketing()) {
                    const manager = await managerApi.getManagerByUserId(initialUserInfo?.id);
                    if (!manager) {
                        setUserManager("error");
                        return;
                    }
                    um.setManager(manager);
                }
                setUserManager(um);
            }
            catch {
                setUserManager("error");
            }
        })();
    }, [initialUserInfo, endCustomerApi, promoterApi, managerApi]);
    return userManager;
};
const AppContextProvider = ({ children, userManager }) => {
    const context = {
        userManager,
    };
    return React.createElement(AppContext.Provider, { value: context }, children);
};
export default AppContextProvider;
