import React from "react";
import PageContainer from "components/commons/PageContainer";
import animationData from "./animations/error.json";
import Lottie from "react-lottie";
import styles from "./styles/index.scss";
import { useNavigate } from "react-router-dom";
const ErrorPage = ({ showHomepageButton = true }) => {
    let navigate = useNavigate();
    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    const handleGoToHomepage = () => {
        navigate("/", { replace: true });
    };
    return (React.createElement(PageContainer, null,
        React.createElement("div", { className: styles.root },
            React.createElement(Lottie, { options: defaultOptions, height: 200, width: 200 }),
            React.createElement("h4", { className: styles.errorCode }, "Internal Server Error"),
            React.createElement("h3", { className: styles.errorCodeText }, "Oops, something went wrong!"),
            React.createElement("p", { className: styles.errorText }, "It seems like the server encounter a problem. We suggest you to try one of these:"),
            React.createElement("ul", { className: styles.list },
                React.createElement("li", null, "Refresh your page"),
                React.createElement("li", null, "Check if your internet connexion is working. If not, restart it and try loading this page again."),
                React.createElement("li", null,
                    "Contact-us with the contact form ",
                    React.createElement("a", { href: "/public/contact" }, "here"))),
            showHomepageButton && (React.createElement("div", { className: styles.button, role: "button", onClick: handleGoToHomepage }, "Go to homepage")))));
};
export default ErrorPage;
