import React, { useState } from "react";
import styles from "./styles/index.scss";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import classNames from "classnames";
const PasswordInput = ({ onChange, name, error = false }) => {
    const [visible, setVisible] = useState(false);
    return (React.createElement("div", { className: styles.container },
        React.createElement("input", { className: classNames(styles.input, {
                [styles.errorInput]: error,
            }), onChange: onChange, type: visible ? "text" : "password", id: name, key: name, name: name, placeholder: "" }),
        visible ? (React.createElement(VisibilityOffIcon, { className: styles.icon, onClick: () => {
                setVisible(false);
            } })) : (React.createElement(VisibilityIcon, { className: styles.icon, onClick: () => {
                setVisible(true);
            } }))));
};
export default PasswordInput;
