import React, { Suspense, useEffect } from "react";
import useAppContext from "components/AppContextProvider/hooks/useAppContext";
import Loader from "components/Loader";
import { isMobileOnly } from "react-device-detect";
import { Navigate, Route, Routes } from "react-router-dom";
import { analyticsIdentify } from "hooks/useAnalytics";
import { BehaviorSubject } from "rxjs";
import useAnalyticsApi from "api/hooks/useAnalyticsApi";
import * as _ from "lodash";
import { distinctUntilChanged } from "rxjs/operators";
import { endCustomerContractForEndCustomerQuery } from "queries/fetchContractsByEndCustomerId";
import { useSuspenseQuery } from "@tanstack/react-query";
import noQuery from "queries/noQuery";
import { useAuth } from "react-cognito-authentifier";
const supportedBrowsers = require("supportedBrowsers.js");
const FarmerEditPage = React.lazy(() => import("page/FarmerEditPage"));
const FarmerUpdateDeclaredDataPage = React.lazy(() => import("page/FarmerUpdateDeclaredDataPage"));
const PortalPage = React.lazy(() => import("page/PortalPage"));
const ContractListPage = React.lazy(() => import("page/ContractListPage"));
const ContractCreationPage = React.lazy(() => import("page/ContractCreationPage"));
const ContractDetailPage = React.lazy(() => import("page/ContractDetailPage"));
const FarmerDetailPage = React.lazy(() => import("page/FarmerDetailPage"));
const ContractDashboardSection = React.lazy(() => import("page/ContractDetailPage/ContractDashboardSection"));
const UserListPage = React.lazy(() => import("page/UserListPage"));
const OutdatedBrowserPage = React.lazy(() => import("page/OutdatedBrowserPage"));
const ContactPage = React.lazy(() => import("page/ContactPage"));
const NoMatchPage = React.lazy(() => import("page/NoMatchPage"));
const FarmerRegisterFormPage = React.lazy(() => import("page/FarmerRegisterFormPage"));
const MyAccountSection = React.lazy(() => import("page/MyAccountPage"));
const CustomersSection = React.lazy(() => import("page/CustomersPage"));
const AnalyticsPage = React.lazy(() => import("page/AnalyticsPage"));
const HistoryPage = React.lazy(() => import("page/HistoryPage"));
export const AnalyticsStream$ = new BehaviorSubject({});
const AppRouter = () => {
    const { userManager } = useAppContext();
    const user = userManager.getUser();
    const endCustomer = userManager.getEndCustomer();
    const auth = useAuth();
    const { data: contract } = useSuspenseQuery(endCustomer
        ? endCustomerContractForEndCustomerQuery(auth, endCustomer.id)
        : noQuery);
    analyticsIdentify(user);
    const analytics = useAnalyticsApi();
    useEffect(() => {
        const subscription = AnalyticsStream$.pipe(distinctUntilChanged()).subscribe((event) => {
            if (!_.isEmpty(event)) {
                analytics.track(event);
            }
        });
        return () => {
            subscription.unsubscribe();
        };
    }, []);
    if (!supportedBrowsers.test(navigator.userAgent) || isMobileOnly) {
        return React.createElement(OutdatedBrowserPage, { isSmartphone: isMobileOnly });
    }
    if (userManager.isFarmer() && !userManager.hasAcceptedPlatformLicenceAgreement()) {
        return React.createElement(FarmerRegisterFormPage, null);
    }
    return (React.createElement(Suspense, { fallback: React.createElement(Loader, { size: "small" }) },
        React.createElement(Routes, null,
            React.createElement(Route, { path: "/", element: userManager.isFarmer() ? (userManager.isSuperFarmer() ? (React.createElement(ContractDashboardSection, { contract: contract?.contract, displayContractFieldMode: true })) : (React.createElement(Navigate, { to: `/farmers/${userManager.getEndCustomer().id}`, replace: true }))) : (React.createElement(PortalPage, null)) }),
            React.createElement(Route, { element: !userManager.isFarmer() ? React.createElement(ContractListPage, null) : React.createElement(NoMatchPage, null), path: "/contracts" }),
            React.createElement(Route, { element: userManager.isAdmin() ? React.createElement(ContractCreationPage, null) : React.createElement(NoMatchPage, null), path: "/contracts/new" }),
            React.createElement(Route, { element: !userManager.isFarmer() ? React.createElement(ContractDetailPage, null) : React.createElement(NoMatchPage, null), path: "/contracts/:contractId/*" }),
            React.createElement(Route, { path: "/farmers/:endCustomerID/*", element: React.createElement(FarmerDetailPage, null) }),
            React.createElement(Route, { path: "/farmers/edit/:endCustomerID", element: React.createElement(FarmerEditPage, null) }),
            React.createElement(Route, { path: "/farmers/update-declared-data/:endCustomerID", element: React.createElement(FarmerUpdateDeclaredDataPage, null) }),
            React.createElement(Route, { path: "/contact", element: React.createElement(ContactPage, null) }),
            React.createElement(Route, { path: "/users/*", element: React.createElement(UserListPage, null) }),
            React.createElement(Route, { element: userManager.isAdmin() ? React.createElement(AnalyticsPage, null) : React.createElement(NoMatchPage, null), path: "/analytics" }),
            React.createElement(Route, { path: "/my-account/*", element: React.createElement(MyAccountSection, null) }),
            React.createElement(Route, { element: userManager.isAdmin() ? React.createElement(HistoryPage, null) : React.createElement(NoMatchPage, null), path: "/history/:id" }),
            React.createElement(Route, { element: userManager.isAdmin() ? React.createElement(CustomersSection, null) : React.createElement(NoMatchPage, null), path: "/customers/*" }),
            React.createElement(Route, { path: "*", Component: NoMatchPage }))));
};
export default AppRouter;
