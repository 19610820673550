import React from "react";
import classNames from "classnames";
import styles from "./index.scss";
import BackLink from "./BackLink";
const SubNavBar = ({ displayBackBtn = true, sticky = true, backBtnTo, children, rightComponent }) => (React.createElement("div", { className: classNames(styles.root, { [styles.sticky]: sticky }) },
    React.createElement("div", { className: styles.container },
        React.createElement("div", { className: styles.placeholder }, displayBackBtn && React.createElement(BackLink, { to: backBtnTo })),
        React.createElement("div", { className: styles.linkContainer }, children),
        React.createElement("div", null,
            React.createElement("div", { className: styles.rightContainer }, rightComponent),
            React.createElement("div", { className: styles.placeholder })))));
export default SubNavBar;
export { default as BackLink } from "./BackLink";
export { default as SubNavBarlink } from "./SubNavBarLink";
export { default as SubNavBarDropDown } from "./SubNavBarDropDown";
