import styles from "./styles/index.scss";
import React from "react";
import PageFooterItem from "./PageFooterItem";
import { useTranslation } from "react-i18next";
import urljoin from "url-join";
import useAppConfiguration from "../../hooks/useAppConfiguration";
import useVersion from "hooks/useVersion";
const PageFooter = ({ public: publicLink = false }) => {
    const { t } = useTranslation();
    const { displayPublicContractPage } = useAppConfiguration();
    const appVersion = useVersion();
    return (React.createElement("div", { className: styles.root },
        (!publicLink || (publicLink && displayPublicContractPage)) && (React.createElement(PageFooterItem, { text: t("footer.contact"), to: urljoin("/", publicLink ? "public" : "", "contact"), external: false })),
        appVersion && appVersion.licenseAgreement && (React.createElement(PageFooterItem, { text: t("footer.license_agreement"), to: appVersion.licenseAgreement, external: true })),
        appVersion && appVersion.privacyPolicy && (React.createElement(PageFooterItem, { text: t("footer.privacy_notice"), to: appVersion.privacyPolicy, external: true })),
        appVersion && appVersion.termsAndConditions && (React.createElement(PageFooterItem, { text: t("footer.terms_condition"), to: appVersion.termsAndConditions, external: true }))));
};
export default PageFooter;
export { default as PageFooterItem } from "./PageFooterItem";
