import { useEffect } from "react";
import { AnalyticsStream$ } from "App/AppRouter";
export const useAnalyticsPage = (name) => {
    useEffect(() => {
        AnalyticsStream$.next({
            page: name
        });
        // eslint-disable-next-line no-underscore-dangle
        if (window && window._paq) {
            // eslint-disable-next-line no-underscore-dangle
            const paq = window._paq || [];
            paq.push(["trackPageView", name]);
        }
    }, []);
};
export const analyticsTrack = (action, data) => {
    AnalyticsStream$.next({
        action,
        data: data || {}
    });
    // eslint-disable-next-line no-underscore-dangle
    if (window && window._paq) {
        // eslint-disable-next-line no-underscore-dangle
        const paq = window._paq || [];
        paq.push(["trackEvent", "Action", action, JSON.stringify(data)]);
    }
};
export const analyticsIdentify = (user) => {
    if (user && user.email) {
        //
        // eslint-disable-next-line no-underscore-dangle
        if (window && window._paq) {
            // eslint-disable-next-line no-underscore-dangle
            const paq = window._paq || [];
            paq.push(["setUserId", user.email]);
        }
    }
};
