import React, { useContext } from "react";
import styles from "./styles/UserDropDownItem.module.scss";
import classNames from "classnames";
import MenuCloserContext from "./MenuCloserContext";
const UserDropDownItem = ({ icon, text, onClick, disabled = false, }) => {
    const closeMenu = useContext(MenuCloserContext);
    if (!closeMenu) {
        throw new Error("Expected this component to be rendered inside a MenuCloserContext");
    }
    const handleClick = (event) => {
        if (onClick) {
            onClick(event);
        }
        closeMenu();
    };
    return (React.createElement("div", { className: classNames(styles.root, { [styles.disabled]: disabled }), onClick: handleClick },
        React.createElement("div", { className: styles.iconContainer }, icon && (React.createElement("img", { src: icon, alt: "drop down item", className: styles.icon }))),
        React.createElement("div", { className: styles.text }, text)));
};
export default UserDropDownItem;
