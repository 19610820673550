import { Auth, Hub } from "aws-amplify";
import React, { createContext, useContext, useEffect, useRef, useState, } from "react";
import Credentials from "./Credentials";
import LoaderScreen from "./LoaderScreen";
import UserProvider from "./UserProvider";
import { clearCredentials, saveCredentials } from "./utils/storage";
const changePassword = async (oldPasswd, newPasswd) => {
    const user = await Auth.currentAuthenticatedUser();
    if (user) {
        await Auth.changePassword(user, oldPasswd, newPasswd);
    }
};
export const buildAuth = (loginUserFn) => {
    const credentials = new Credentials();
    const authFetch = async (url, init = {}) => {
        let session;
        try {
            session = await Auth.currentSession();
        }
        catch {
            // User is not connected
            // Do not attach any token
            return fetch(url, init);
        }
        const accessToken = session.getAccessToken().getJwtToken();
        const initWithToken = { ...init };
        initWithToken.headers = new Headers(init.headers);
        initWithToken.headers.set("Authorization", `Bearer ${accessToken}`);
        return fetch(url, initWithToken);
    };
    const authIdFetch = async (url, init) => {
        let session;
        try {
            session = await Auth.currentSession();
        }
        catch {
            // User is not connected
            // Do not attach any token
            return fetch(url, init);
        }
        const idToken = session.getIdToken().getJwtToken();
        const initWithToken = { ...init };
        initWithToken.headers = new Headers(init.headers);
        initWithToken.headers.set("Authorization", `Bearer ${idToken}`);
        return fetch(url, initWithToken);
    };
    return {
        credentials,
        fetch: authFetch,
        idFetch: authIdFetch,
        signIn: loginUserFn,
        signOut: (options = {}) => {
            const local = options.local ?? false;
            const global = !local;
            return Auth.signOut({ global });
        },
        changePassword,
    };
};
export var AuthStateEnum;
(function (AuthStateEnum) {
    AuthStateEnum[AuthStateEnum["signOut"] = 0] = "signOut";
    AuthStateEnum[AuthStateEnum["loading"] = 1] = "loading";
    AuthStateEnum[AuthStateEnum["signIn"] = 2] = "signIn";
    AuthStateEnum[AuthStateEnum["signedIn"] = 3] = "signedIn";
})(AuthStateEnum || (AuthStateEnum = {}));
export const AuthContext = createContext(undefined);
export const useAuth = () => {
    const auth = useContext(AuthContext);
    if (auth === undefined) {
        throw new Error("Expected this hook to be called inside an Api context");
    }
    return auth;
};
const AuthProvider = ({ children, displayLogo = true, loader, ...props }) => {
    const { showProtected = false } = props;
    const [authState, setAuthState] = useState(AuthStateEnum.signOut);
    const loginUser = () => {
        setAuthState(AuthStateEnum.signIn);
        Auth.federatedSignIn();
    };
    const authRef = useRef(buildAuth(loginUser));
    useEffect(() => {
        const { credentials } = authRef.current;
        const { autoLogin = true } = props;
        // Listen to amplify storage event to synchronize user credentials state
        Hub.listen("StorageUpdate", (data) => {
            switch (data.payload.event) {
                case "set-accessToken":
                    credentials.setAccessToken(data.payload.data);
                    break;
                case "set-refreshToken":
                    credentials.setAccessToken(data.payload.data);
                    saveCredentials({
                        refreshToken: data.payload.data,
                    });
                    break;
                case "remove-accessToken":
                case "remove-refreshToken":
                case "clear":
                    credentials.clearTokens();
                    clearCredentials();
                    break;
            }
        });
        // Retrieve existing user info
        Auth.currentAuthenticatedUser()
            .then((user) => {
            // Save user credentials in auth context
            const session = user.getSignInUserSession();
            if (session) {
                credentials.setIdToken(session.getIdToken().getJwtToken());
                credentials.setAccessToken(session.getAccessToken().getJwtToken());
                credentials.setRefreshToken(session.getRefreshToken().getToken());
                saveCredentials({
                    refreshToken: session.getRefreshToken().getToken(),
                });
                setAuthState(AuthStateEnum.signedIn);
            }
        })
            .catch((err) => {
            console.log(err);
            // eslint-disable-next-line no-console
            console.log("No authenticated user");
            if (autoLogin) {
                loginUser();
            }
        });
    }, []);
    const renderChildrens = () => {
        switch (authState) {
            case AuthStateEnum.signedIn:
                return (React.createElement(UserProvider, { userApiConfig: props.userApiConfig, displayLogo: displayLogo, logo: props.logo, loader: loader }, children));
            case AuthStateEnum.signIn:
                return (React.createElement(LoaderScreen, { displayLogo: displayLogo, logo: props.logo, text: "Authenticating" }));
            case AuthStateEnum.signOut:
                if (showProtected) {
                    return children;
                }
                return null;
            default:
                return null;
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(AuthContext.Provider, { value: authRef.current }, renderChildrens())));
};
export { AuthProvider };
export default AuthProvider;
