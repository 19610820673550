import React from "react";
import Loader from "react-loader-spinner";
import colors from "theme/colors";
import styles from "./styles/loader.scss";
const sizes = {
    xxsmall: 15,
    xsmall: 30,
    small: 40,
    medium: 50,
    large: 100,
    xlarge: 200
};
export const CoreLoader = ({ size }) => (React.createElement(Loader, { type: "TailSpin", color: colors.axa_blue, width: sizes[size], height: sizes[size] }));
export const LoaderWithContainer = ({ size }) => (React.createElement("div", { className: styles.loaderContainer },
    React.createElement(CoreLoader, { size: size })));
