import urljoin from "url-join";
import { BaseAPI } from "./BaseApi";
import { apiGet, apiPost } from "./helpers";
const ContactRessourceName = "contact";
class ContactMessageApi extends BaseAPI {
    getContacts = async (options = {}) => {
        const { basePath, fetch: customFetch } = this;
        const contracts = await apiGet(urljoin(basePath, `/${ContactRessourceName}`), customFetch, options);
        return contracts;
    };
    getcontact = async (id, options = {}) => {
        const { basePath, fetch: customFetch } = this;
        return apiGet(urljoin(basePath, ContactRessourceName, encodeURIComponent(id)), customFetch, options);
    };
    createContact = async (data, options = {}) => {
        return apiPost(urljoin(this.basePath, `/${ContactRessourceName}`), this.fetch, data, options);
    };
}
export default ContactMessageApi;
