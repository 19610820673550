import React, { useState } from "react";
import { Auth } from "aws-amplify";
import checkPassword from "../../checkPassword";
import { useTranslation } from "react-i18next";
import PasswordInput from "../../commons/PasswordInput";
import styles from "./styles/ChangePasswdScreen.scss";
export const InvalidPasswordMessage = ({ policy }) => {
    const { t } = useTranslation();
    if (!policy) {
        return (React.createElement("div", { className: styles.errorContainer }, t("change_password.invalid.invalid")));
    }
    const messages = [
        t("change_password.invalid.too_short", { minChars: policy.minimumLength }),
    ];
    if (policy.requireLowercase) {
        messages.push(t("change_password.invalid.needs_lowercase"));
    }
    if (policy.requireUppercase) {
        messages.push(t("change_password.invalid.needs_uppercase"));
    }
    if (policy.requireNumbers) {
        messages.push(t("change_password.invalid.needs_digit"));
    }
    if (policy.requireSymbols) {
        messages.push(t("change_password.invalid.needs_symbol"));
    }
    return (React.createElement("div", { className: styles.validationErrorContainer },
        React.createElement("p", null, t("change_password.invalid.invalid_rules")),
        React.createElement("ul", null, messages.map((m) => (React.createElement("li", { key: m }, m))))));
};
const ChangePasswdScreen = ({ onStateChange, authData, config, }) => {
    const { t } = useTranslation();
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [error, setError] = useState("");
    const passwordMatch = password === passwordConfirmation;
    const passwordIsValid = checkPassword(password, config?.passwordPolicy) === null;
    const completeNewPasswordSubmit = async (event) => {
        setError("");
        event.preventDefault();
        const user = authData;
        const requiredAttributes = user.challengeParam.requiredAttributes;
        try {
            const resultUser = await Auth.completeNewPassword(user, password, requiredAttributes);
            await Auth.verifiedContact(resultUser);
        }
        catch (err) {
            setError(err.message);
            onStateChange("signIn", {});
        }
    };
    const onPasswordChange = (evt) => {
        setPassword(evt.target.value);
    };
    const onPasswordConfirmationChange = (evt) => {
        setPasswordConfirmation(evt.target.value);
    };
    return (React.createElement("form", { className: styles.formContainer, onSubmit: completeNewPasswordSubmit },
        React.createElement("div", { className: styles.title }, t("change_password.title")),
        error && (React.createElement("div", { className: styles.errorContainer }, t("change_password.fail", { error }))),
        React.createElement("label", { className: styles.label, htmlFor: "new_password" }, t("change_password.new_password")),
        React.createElement(PasswordInput, { onChange: onPasswordChange, name: "new_password" }),
        !!password && !passwordIsValid && (React.createElement(InvalidPasswordMessage, { policy: config?.passwordPolicy })),
        React.createElement("label", { className: styles.label, htmlFor: "password_confirmation" }, t("change_password.password_confirmation")),
        React.createElement(PasswordInput, { onChange: onPasswordConfirmationChange, name: "password_confirmation", error: !passwordMatch }),
        React.createElement("p", null),
        React.createElement("button", { className: styles.button, disabled: !password ||
                !passwordConfirmation ||
                !passwordMatch ||
                !passwordIsValid }, t("change_password.change_password"))));
};
export default ChangePasswdScreen;
