import { AppContext } from "..";
import React from "react";
const useAppContext = () => {
    const context = React.useContext(AppContext);
    if (context === undefined) {
        throw new Error("useAppContext must be used within a AppContextProvider");
    }
    return context;
};
export default useAppContext;
