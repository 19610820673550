/* eslint-disable @typescript-eslint/naming-convention */
export default {
    // blue
    deep_sapphire: "#00005b",
    axa_blue: "#00008f",
    st_tropaz: "#2425aa",
    azure: "#3032c1",
    dark_indigo: "#3b3fd8",
    indigo: "#494df4",
    // grey
    mine_shaft: "#333333",
    scorpion: "#5f5f5f",
    gray: "#7f7f7f",
    dusty_gray: "#999999",
    mercury: "#e5e5e5",
    wild_sand: "#f5f5f5",
    alabaster: "#fafafa",
    white: "#fff",
    warmgrey: "#7f7f7f",
    // red
    axa_red: "#ff1721",
    burnt_sienna: "#f07662",
    flamingo: "#ec4d33",
    shy_tomato: "#c91432",
    // green
    malachite: "#1cc54e",
    vineyardgreen: "#9fd9b3"
};
