import React from "react";
import AppContextProvider, { useUserManager } from "components/AppContextProvider";
import styles from "./styles/index.scss";
import PageContainer from "components/commons/PageContainer";
import Navbar, { NavbarGroup, NavbarLogo, LanguageSelector } from "components/commons/Navbar";
import UserAccess from "static/user_access.svg";
import { UserDropDown, UserDropDownItem } from "react-cognito-authentifier";
import { useNavigate } from "react-router-dom";
import AppRouter from "App/AppRouter";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "utils/strings";
import Loader from "components/Loader";
import ErrorPage from "components/ErrorPage";
import ENV_CONFIG from "env";
import CurrentTimeContextProvider from "components/CurrentTimeContextProvider";
import { ErrorBoundary } from "@sentry/react";
const AuthenticatedApp = () => {
    const userManager = useUserManager();
    let navigate = useNavigate();
    const { t } = useTranslation();
    if (!userManager) {
        return React.createElement(Loader, { size: "small" });
    }
    return (React.createElement("div", { className: styles.root },
        React.createElement(PageContainer, null,
            React.createElement(Navbar, { sticky: true },
                React.createElement(NavbarGroup, { to: "/" },
                    React.createElement(NavbarLogo, { image: ENV_CONFIG.LOGO_LINK })),
                React.createElement(NavbarGroup, null,
                    userManager !== "error" &&
                        (userManager.isAdmin() || userManager.isMarketing()) && (React.createElement(LanguageSelector, { style: { marginRight: "40px" }, langs: [
                            {
                                key: "fr",
                                flagUrl: "/static/locales/fr/flag.svg"
                            },
                            {
                                key: "en",
                                flagUrl: "/static/locales/en/flag.svg"
                            },
                            {
                                key: "ro",
                                flagUrl: "/static/locales/ro/flag.svg"
                            },
                            {
                                key: "hu",
                                flagUrl: "/static/locales/hu/flag.svg"
                            }
                        ] })),
                    React.createElement(UserDropDown, { onLogout: () => {
                            navigate("/");
                        }, logoutText: capitalizeFirstLetter(t("dropdown.logout")) }, userManager !== "error" &&
                        (userManager.isPromoter() ||
                            userManager.isMarketing() ||
                            (userManager.isFarmer() &&
                                userManager.isRegisteredFarmer())) && (React.createElement(UserDropDownItem, { icon: UserAccess, text: capitalizeFirstLetter(t("dropdown.my_account")), onClick: () => {
                            navigate("/my-account");
                        } }))))),
            userManager !== "error" ? (React.createElement(ErrorBoundary, { fallback: React.createElement(ErrorPage, { showHomepageButton: false }) },
                React.createElement(CurrentTimeContextProvider, null,
                    React.createElement(AppContextProvider, { userManager: userManager },
                        React.createElement(AppRouter, null))))) : (React.createElement(ErrorPage, { showHomepageButton: false })))));
};
export default AuthenticatedApp;
