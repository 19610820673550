import isomorphicFetch from "isomorphic-fetch";
import urljoin from "url-join";
// In production, the backend has the same address as the front because it serves it directly
const urlBasename = process.env.REACT_APP_SERVER_HOSTNAME || window.location.origin;
export async function fetchOrFail(url, options, fetch = isomorphicFetch) {
    const res = await fetch(url, options);
    if (!res.ok) {
        const error = new Error(`${res.status} code when requesting ${res.url}`);
        error.status = res.status;
        try {
            error.json = await res.json();
        }
        catch {
            // eslint-disable-next-line no-console
            console.warn("Non-2xx response received without a JSON body");
        }
        throw error;
    }
    return res;
}
export async function fetchJson(fetch, path, method = "GET", initHeaders = {}, body, json = true, mode = "cors") {
    const headers = new Headers(initHeaders);
    if (json) {
        headers.set("Content-Type", "application/json;charset=UTF-8");
    }
    headers.set("Origin", window.location.origin);
    const options = {
        headers,
        method,
        body,
        mode
    };
    if (body) {
        if (body instanceof FormData || !json) {
            options.body = body;
        }
        else {
            options.body = JSON.stringify(body);
        }
    }
    const res = await fetchOrFail(path, options, fetch);
    const contentType = res.headers.get("Content-Type");
    if (!contentType || contentType.indexOf("application/json") === -1) {
        return null;
    }
    return res.json();
}
export async function fetchData(fetch, url, method = "GET", initHeaders = {}, body, json = true) {
    const headers = new Headers(initHeaders);
    if (json) {
        headers.set("Content-Type", "application/json;charset=UTF-8");
    }
    const options = {
        headers,
        method,
        body
    };
    if (body) {
        if (body instanceof FormData || !json) {
            options.body = body;
        }
        else {
            options.body = JSON.stringify(body);
        }
    }
    const res = await fetch(url, options);
    return res;
}
export async function apiGet(url, fetch = isomorphicFetch, headers = {}, json = true
// mode: RequestMode = 'cors'
) {
    return fetchJson(fetch, url, "GET", headers, null, json);
}
export async function apiGetData(url, fetch = isomorphicFetch, headers = {}, json = true) {
    return fetchData(fetch, url, "GET", headers, null, json);
}
export async function apiPost(url, fetch = isomorphicFetch, body, headers = {}, json = true) {
    return fetchJson(fetch, url, "POST", headers, body, json);
}
export async function apiPatch(url, fetch = isomorphicFetch, body, headers = {}, json = true) {
    return fetchJson(fetch, url, "PATCH", headers, body, json);
}
export async function apiPostMultipart(url, fetch = isomorphicFetch, body, headers = {}) {
    return fetchJson(fetch, url, "POST", headers, body, false);
}
export async function apiPutMultipart(url, fetch = isomorphicFetch, body, headers = {}) {
    return fetchJson(fetch, url, "PUT", headers, body, false);
}
export async function apiPut(url, fetch = isomorphicFetch, body, headers = {}, json = true
// mode: RequestMode = 'cors'
) {
    return fetchJson(fetch, url, "PUT", headers, body, json);
}
export async function apiDelete(url, fetch = isomorphicFetch, body, headers = {}, json = true) {
    return fetchJson(fetch, url, "DELETE", headers, body, json);
}
export function computeUrl(path) {
    return urljoin(urlBasename, path);
}
export function computeUrlWithToken(url, token) {
    let fullUrl = computeUrl(url);
    if (fullUrl.indexOf("?") > -1) {
        fullUrl += `&access_token=${token}`;
        return fullUrl;
    }
    fullUrl += `?access_token=${token}`;
    return fullUrl;
}
