import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./DropDown.scss";
import classNames from "classnames";
const DropDown = ({ MenuItems }) => {
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);
    return (React.createElement(React.Fragment, null,
        React.createElement("ul", { onClick: handleClick, className: classNames({
                [styles.dropdownMenu]: click,
                [styles.clicked]: click,
                [styles.dropdownMenu]: !click
            }) }, MenuItems.map((item) => {
            return (React.createElement("li", { key: item.index },
                React.createElement(Link, { className: styles.dropdownLink, to: item.to, onClick: item.onClick }, item.name)));
        }))));
};
export default DropDown;
