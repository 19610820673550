import { getEndCustomersContractsApi } from "api/hooks/useEndCustomerContractsApi";
import QueryKeys from "queryKeys";
export function endCustomerContractForEndCustomerQuery(auth, endCustomerID) {
    return {
        queryKey: [new QueryKeys().fetchContractsByEndCustomerId, endCustomerID],
        queryFn: async () => {
            return getEndCustomersContractsApi(auth).getEndCustomerContractForEndCustomer(endCustomerID);
        },
    };
}
