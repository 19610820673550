import moment from "moment";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
const CurrentTimeContext = createContext(null);
function useRealTime() {
    const [realTime, setRealTime] = useState(new Date());
    useEffect(() => {
        const interval = setInterval(() => {
            setRealTime(new Date());
        }, 60000); // Every minute
        return () => {
            clearInterval(interval);
        };
    }, []);
    return realTime;
}
function useFakeTime() {
    const location = useLocation();
    if (process.env.ENVIRONMENT === "prod")
        return null;
    const params = new URLSearchParams(location.search);
    const fakeDate = params.get("fakeDate");
    if (!fakeDate)
        return null;
    return moment(fakeDate, "DD/MM/YYYY").toDate();
}
function CurrentTimeContextProvider({ children }) {
    const realTime = useRealTime();
    const fakeTime = useFakeTime();
    const currentTime = fakeTime ?? realTime;
    return React.createElement(CurrentTimeContext.Provider, { value: currentTime, children: children });
}
export function useCurrentTime() {
    const currentTime = useContext(CurrentTimeContext);
    if (!currentTime)
        throw new Error("expected to be called inside a CurrentTimeContext");
    return currentTime;
}
export default CurrentTimeContextProvider;
