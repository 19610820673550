import { Auth } from "aws-amplify";
import React, { useContext, useEffect, useRef, useState } from "react";
import { UsersInfoApi } from "./api/UsersApi";
import { useAuth } from "./AuthProvider";
import LoaderScreen from "./LoaderScreen";
export const UserProfileContext = React.createContext({
    api: new UsersInfoApi({}, ""),
    initialUserInfo: null,
});
export const useUserProfile = () => {
    const userProfile = useContext(UserProfileContext);
    if (!userProfile) {
        throw new Error("Expected useUserProfile to be called inside a UserProfileContext");
    }
    return userProfile;
};
const UserProvider = ({ children, userApiConfig, displayLogo = true, logo, loader: Loader, }) => {
    const auth = useAuth();
    const [initialUserInfo, setInitialUserInfo] = useState(null);
    const buildUserContext = () => {
        return {
            api: new UsersInfoApi({}, userApiConfig ? userApiConfig.host : "", auth.fetch),
            initialUserInfo,
        };
    };
    const userCtxRef = useRef(buildUserContext());
    useEffect(() => {
        // Retrieve existing user info
        Auth.currentAuthenticatedUser().then((user) => {
            user.getUserAttributes((error, attributes = []) => {
                if (error) {
                    if (error.code === "NotAuthorizedException") {
                        // Access token has been revoked
                        // Return the user to the login page
                        Auth.signOut();
                        return;
                    }
                    throw error;
                }
                const session = user.getSignInUserSession();
                const userAttributes = {};
                attributes.forEach((a) => {
                    userAttributes[a.getName()] = a.getValue();
                });
                if (session) {
                    setInitialUserInfo({
                        id: user.getUsername(),
                        email: userAttributes.email,
                        phone_number: userAttributes.phone_number,
                        username: userAttributes.preferred_username,
                        groups: session.getAccessToken().decodePayload()["cognito:groups"],
                    });
                }
            });
        }, () => {
            // eslint-disable-next-line no-console
            console.error("[User provider] No authenticated user");
            // Should not happened but if user is not authenticated by Amplify, logout.
            auth.signOut();
        });
    }, []);
    if (initialUserInfo === null) {
        return Loader ? (React.createElement(Loader, null)) : (React.createElement(LoaderScreen, { displayLogo: displayLogo, logo: logo }));
    }
    return (React.createElement(UserProfileContext.Provider, { value: { ...userCtxRef.current, initialUserInfo } }, children));
};
export { UserProvider };
export default UserProvider;
