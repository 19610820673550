/* eslint-disable @typescript-eslint/naming-convention */
export const VIEW_PURCHASED_PRODUCT = "view_purchased_product";
export const VIEW_END_CUSTOMER = "view_end_customer";
export const VIEW_FIELD = "view_field";
export var INSURED_PRODUCTS;
(function (INSURED_PRODUCTS) {
    INSURED_PRODUCTS["COLZA"] = "colza";
    INSURED_PRODUCTS["CORN"] = "corn";
    INSURED_PRODUCTS["BARLEY"] = "barley";
    INSURED_PRODUCTS["PEA"] = "pea";
})(INSURED_PRODUCTS || (INSURED_PRODUCTS = {}));
export const DEFAULT_INSURED_PRODUCT = INSURED_PRODUCTS.COLZA;
export const MODE_SIMPLE_FORM = "form";
export const MODE_UPLOAD_SHAPEFILE = "shapefile";
