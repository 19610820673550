class Credentials {
    idToken = "";
    refreshToken = "";
    accessToken = "";
    tokenExpiration = 20;
    getIdToken = () => this.idToken;
    getRefreshToken = () => this.refreshToken;
    getAccessToken = () => this.accessToken;
    setIdToken = (token) => (this.idToken = token);
    setAccessToken = (token) => (this.accessToken = token);
    setRefreshToken = (token) => (this.refreshToken = token);
    getTokenExpiration = () => this.tokenExpiration;
    setTokenExpiration = (expiration) => (this.tokenExpiration = expiration);
    clearTokens = () => {
        this.refreshToken = "";
        this.accessToken = "";
        this.idToken = "";
    };
    setCredentials = (credentials) => {
        this.idToken = credentials.idToken || this.idToken;
        this.refreshToken = credentials.refreshToken || this.refreshToken;
        this.accessToken = credentials.accessToken || this.accessToken;
        this.tokenExpiration = credentials.expiresIn || this.tokenExpiration;
    };
}
export default Credentials;
