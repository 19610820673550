import urljoin from "url-join";
import { BaseAPI } from "./BaseApi";
import { apiGet, apiPatch, apiPost, apiDelete } from "./helpers";
import { toQueryString } from "utils/api";
class EndCustomersApi extends BaseAPI {
    getEndCustomers = async (queryOptions = {}) => {
        const { basePath, fetch: customFetch } = this;
        const query = Object.keys(queryOptions).length
            ? toQueryString(queryOptions)
            : "";
        const endCustomers = await apiGet(urljoin(basePath, "/end-customer", query), customFetch);
        return endCustomers;
    };
    getEndCustomer = async (id, options = {}) => {
        const { basePath, fetch: customFetch } = this;
        return apiGet(urljoin(basePath, "end-customer", encodeURIComponent(id)), customFetch, options);
    };
    getEndCustomerByUserId = async (userId, options = {}) => {
        if (!userId)
            return undefined;
        const { basePath, fetch: customFetch } = this;
        return apiGet(urljoin(basePath, "end-customer", "userId", encodeURIComponent(userId)), customFetch, options);
    };
    updateEndCustomer = async (id, data, options = {}) => {
        const { basePath, fetch: customFetch } = this;
        return apiPatch(urljoin(basePath, "end-customer", encodeURIComponent(id)), customFetch, data, options);
    };
    activateAllEndCustomer = async (data, options = {}) => {
        const { basePath, fetch: customFetch } = this;
        return apiPost(urljoin(basePath, "end-customer", "activate-users"), customFetch, {
            users: data
        }, options);
    };
    deactivateAllEndCustomer = async (data, options = {}) => {
        const { basePath, fetch: customFetch } = this;
        return apiPost(urljoin(basePath, "end-customer", "deactivate-users"), customFetch, {
            users: data
        }, options);
    };
    registerUser = async (id, data, options = {}) => {
        const { basePath, fetch: customFetch } = this;
        return apiPatch(urljoin(basePath, "end-customer", encodeURIComponent(id), "registration"), customFetch, data, options);
    };
    activateEndCustomer = async (id) => {
        const { basePath, fetch: customFetch } = this;
        return apiPost(urljoin(basePath, "end-customer", encodeURIComponent(id), "activate"), customFetch);
    };
    deactivateEndCustomer = async (id) => {
        const { basePath, fetch: customFetch } = this;
        return apiPost(urljoin(basePath, "end-customer", encodeURIComponent(id), "deactivate"), customFetch);
    };
    deleteEndCustomer = async (id) => {
        const { basePath, fetch: customFetch } = this;
        return apiDelete(urljoin(basePath, "end-customer", encodeURIComponent(id)), customFetch);
    };
    getEndCustomerFieldsExport = async (id) => {
        const { basePath, fetch: customFetch } = this;
        const resp = await customFetch(urljoin(basePath, "end-customer", "fields-export", encodeURIComponent(id)), { method: "GET" });
        return resp;
    };
    customDeclaration = async (endpoint, id, data, options = {}) => {
        const { basePath, fetch: customFetch } = this;
        return apiPatch(urljoin(basePath, "end-customer", encodeURIComponent(id), endpoint), customFetch, data, options);
    };
    replowDeclaration = async (id, data, options = {}) => {
        const { customDeclaration } = this;
        return customDeclaration("replow-declaration", id, data, options);
    };
    yieldEstimation = async (id, data, options = {}) => {
        const { customDeclaration } = this;
        return customDeclaration("yield-estimation", id, data, options);
    };
    fieldsDeclaration = async (id, data, options = {}) => {
        const { customDeclaration } = this;
        return customDeclaration("fields-declaration", id, data, options);
    };
}
export default EndCustomersApi;
