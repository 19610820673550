import { BaseAPI } from "./BaseApi";
import { apiGet, apiPost } from "./helpers";
import urljoin from "url-join";
class AnalyticsApi extends BaseAPI {
    getAnalytics = (id) => async () => {
        const { basePath, fetch: customFetch } = this;
        return apiGet(urljoin(basePath, "analytics", id), customFetch, {});
    };
    getAnalyticsTrack = async (id) => {
        const { basePath, fetch: customFetch } = this;
        return apiGet(urljoin(basePath, "analytics/track/", id), customFetch, {});
    };
    getAnalyticsEmailList = async () => {
        const { basePath, fetch: customFetch } = this;
        return apiGet(urljoin(basePath, "/analytics/email/list"), customFetch, {});
    };
    track = async (data, options = {}) => {
        return apiPost(urljoin(this.basePath, `/analytics/track`), this.fetch, data, options);
    };
}
export default AnalyticsApi;
