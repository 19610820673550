import React from "react";
import classNames from "classnames";
import FieldErrorMessage from "../FieldErrorMessage";
import styles from "./styles/formTextInput.scss";
import { capitalizeFirstLetter } from "utils/strings";
const FormTextInput = ({ label, fieldName, errors = {}, value = "", disabled = false, inputClassName, formRef }) => (React.createElement("div", { className: styles.root },
    React.createElement("label", { className: classNames(styles.root__label, {
            [styles.root__label_disabled]: disabled
        }), htmlFor: fieldName }, capitalizeFirstLetter(label)),
    React.createElement("input", { disabled: disabled, defaultValue: value || undefined, className: classNames(styles.input, inputClassName, {
            [styles.errorInput]: fieldName in errors
        }), type: "text", name: fieldName, ref: formRef }),
    React.createElement(FieldErrorMessage, { errors: errors, name: fieldName })));
export default FormTextInput;
