import portableFetch from "isomorphic-fetch";
export async function fetchOrFail(url, options, fetch = portableFetch) {
    const res = await fetch(url, options);
    if (!res.ok) {
        const error = new Error(`${res.status} code when requesting ${res.url}`);
        error.status = res.status;
        try {
            error.json = await res.json();
        }
        catch (err) {
            // eslint-disable-next-line no-console
            console.warn("Non-2xx response received without a JSON body");
        }
        throw error;
    }
    return res;
}
export async function fetchJson(fetch, path, method = "GET", initHeaders = {}, body, json = true) {
    const headers = new Headers(initHeaders);
    if (json) {
        headers.set("Content-Type", "application/json;charset=UTF-8");
    }
    const options = {
        headers,
        method,
        body,
    };
    if (body) {
        if (body instanceof FormData || !json) {
            options.body = body;
        }
        else {
            options.body = JSON.stringify(body);
        }
    }
    const res = await fetchOrFail(path, options, fetch);
    const contentType = res.headers.get("Content-Type");
    if (!contentType || contentType.indexOf("application/json") === -1) {
        return null;
    }
    return res.json();
}
export async function apiGet(fetch, url, headers = {}, json = true) {
    return fetchJson(fetch, url, "GET", headers, null, json);
}
export async function apiPost(fetch, url, body, headers = {}, json = true) {
    return fetchJson(fetch, url, "POST", headers, body, json);
}
export async function apiPatch(fetch, url, body, headers = {}, json = true) {
    return fetchJson(fetch, url, "PATCH", headers, body, json);
}
export async function apiPostMultipart(fetch, url, body, headers = {}) {
    return fetchJson(fetch, url, "POST", headers, body, false);
}
export async function apiPutMultipart(fetch, url, body, headers = {}) {
    return fetchJson(fetch, url, "PUT", headers, body, false);
}
export async function apiPut(fetch, url, body, headers = {}, json = true) {
    return fetchJson(fetch, url, "PUT", headers, body, json);
}
export async function apiDelete(fetch, url, body, headers = {}, json = true) {
    return fetchJson(fetch, url, "DELETE", headers, body, json);
}
