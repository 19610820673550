import { Amplify, Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AuthProvider from "./AuthProvider";
import CustomStorage from "./CustomStorage";
import ErrorScreen from "./ErrorScreen";
import LoginEndpoint from "./LoginEndpoint";
import LogoutEndpoint from "./LogoutEndpoint";
import FakeAuthentifier from "./FakeAuthentifier";
const DEFAULT_LOGIN_URL = `${location.protocol}//${location.host}/login`;
const DEFAULT_LOGOUT_URL = `${location.protocol}//${location.host}/logout`;
const RealAuthentifier = ({ children, oAuthConfig, awsConfig, ...props }) => {
    const { redirectSignIn = DEFAULT_LOGIN_URL, redirectSignOut = DEFAULT_LOGOUT_URL, } = oAuthConfig;
    const [criticalError, setCriticalError] = useState(false);
    useEffect(() => {
        // Init Amplify conf
        try {
            Amplify.configure(awsConfig);
            Auth.configure({
                oauth: {
                    ...oAuthConfig,
                    redirectSignIn,
                    redirectSignOut,
                },
                storage: CustomStorage,
            });
        }
        catch {
            setCriticalError(true);
        }
    }, []);
    if (criticalError) {
        return React.createElement(ErrorScreen, null);
    }
    return (React.createElement(Router, null,
        React.createElement(Routes, null,
            redirectSignIn && (React.createElement(Route, { path: new URL(redirectSignIn).pathname, element: React.createElement(LoginEndpoint, null) })),
            redirectSignOut && (React.createElement(Route, { path: new URL(redirectSignOut).pathname, element: React.createElement(LogoutEndpoint, null) })),
            React.createElement(Route, { path: "/*", element: React.createElement(AuthProvider, { ...props }, children) }))));
};
const Authentifier = ({ fake = false, children, ...authProps }) => fake ? (React.createElement(FakeAuthentifier, null, children)) : (React.createElement(RealAuthentifier, { ...authProps }, children));
export default Authentifier;
