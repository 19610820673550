import { useAuth } from "react-cognito-authentifier";
import ENV_CONFIG from "env";
import EndCustomerContractsApi from "api/EndCustomerContractsApi";
export function getEndCustomersContractsApi(auth) {
    return new EndCustomerContractsApi({}, ENV_CONFIG.SPEIRO_API_URL, auth.idFetch);
}
export const useEndCustomersContractsApi = () => {
    const auth = useAuth();
    return getEndCustomersContractsApi(auth);
};
export default useEndCustomersContractsApi;
