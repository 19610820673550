import React from "react";
import classNames from "classnames";
import Navbar from "components/commons/Navbar";
import SubNavBar from "components/commons/SubNavBar";
import styles from "./styles/index.scss";
// TODO: Move to utils ?
const checkChildTypePresent = (children, classType) => {
    if (!children) {
        return false;
    }
    if (!Array.isArray(children)) {
        return children.type === classType;
    }
    return children.find((child) => child.type === classType);
};
const PageContainer = ({ children, fullwidth = true, className, footer: CustomFooter, isScrollable = true }) => {
    const isNavPresent = checkChildTypePresent(children, Navbar);
    const isSubnavPresent = checkChildTypePresent(children, SubNavBar);
    return (React.createElement("div", { className: classNames(styles.root, className) },
        React.createElement("div", { className: classNames(styles.container, {
                [styles.navbar]: isNavPresent,
                [styles.subnavbar]: isSubnavPresent,
                [styles.scrollable]: isScrollable
            }) },
            React.createElement("div", { className: classNames(styles.subContainer, {
                    [styles.fixedWidth]: !fullwidth
                }) }, children)),
        CustomFooter && React.createElement(CustomFooter, null)));
};
export default PageContainer;
