/* eslint-disable no-console */
import React, { useMemo, useEffect } from "react";
import styles from "./styles/index.scss";
import SigninScreen from "./SigninScreen";
import ForgotPasswdScreen from "./ForgotPasswdScreen";
import ChangePasswordScreen from "./ChangePasswdScreen";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import enTranslation from "../../locales/en/translation.json";
import roTranslation from "../../locales/ro/translation.json";
import frTranslation from "../../locales/fr/translation.json";
import cornerImage from "./assets/images/red_corner.svg";
const useI18n = (lang) => {
    const i18n = useMemo(() => {
        const instance = i18next.createInstance({
            resources: {
                en: {
                    translation: enTranslation,
                },
                ro: {
                    translation: roTranslation,
                },
                fr: {
                    translation: frTranslation,
                },
            },
        });
        instance.init();
        return instance;
    }, []);
    useEffect(() => {
        i18n.changeLanguage(lang);
    }, [i18n, lang]);
    return i18n;
};
const CustomScreen = ({ authState, screensConfig = {}, authData, onStateChange, lang, ...props }) => {
    const i18n = useI18n(lang);
    const config = 
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    authState && authState in screensConfig ? screensConfig[authState] : {};
    const { footer: CustomFooter } = screensConfig;
    const renderScreen = () => {
        switch (authState) {
            case "signIn":
                return (React.createElement(SigninScreen, { ...props, config: config, authData: authData, onStateChange: onStateChange }));
            case "forgotPassword":
                return (React.createElement(ForgotPasswdScreen, { ...props, config: config, authData: authData, onStateChange: onStateChange }));
            case "requireNewPassword":
                return (React.createElement(ChangePasswordScreen, { ...props, config: config, authData: authData, onStateChange: onStateChange }));
            default:
                return null;
        }
    };
    if (authState === "signedIn") {
        return null;
    }
    return (React.createElement(I18nextProvider, { i18n: i18n },
        React.createElement("div", { className: styles.root },
            React.createElement("div", { className: styles.container },
                config.displayCover && config.cover && (React.createElement("div", { className: styles.image, style: { backgroundImage: `url(${config.cover})` } })),
                React.createElement("div", { className: styles.content, style: { backgroundImage: `url(${cornerImage})` } },
                    React.createElement("div", { className: styles.logoContainer }, config.logo && React.createElement("img", { src: config.logo, className: styles.logo })),
                    renderScreen())),
            CustomFooter && React.createElement(CustomFooter, null))));
};
export default CustomScreen;
