import React, { useEffect, useState } from "react";
import AuthProvider from "./AuthProvider";
import FakeAuthentifier from "./FakeAuthentifier";
import CustomAuthenticator from "./CustomAuthenticator";
import { Amplify } from "aws-amplify";
import LoaderScreen from "./LoaderScreen";
const RealLocalAuthentifier = ({ children, awsConfig, screensConfig, lang = "en", usernameIsEmailAddress = false, displayLogo, logo, loader: Loader, ...props }) => {
    const [amplifyStatus, setAmplifyStatus] = useState("loading");
    useEffect(() => {
        try {
            Amplify.configure(awsConfig);
        }
        catch {
            setAmplifyStatus("error");
            return;
        }
        setAmplifyStatus("loaded");
    }, []);
    if (amplifyStatus === "loading") {
        return Loader ? (React.createElement(Loader, null)) : (React.createElement(LoaderScreen, { text: "Loading", displayLogo: displayLogo, logo: logo }));
    }
    if (amplifyStatus === "error") {
        return React.createElement("div", null, "Critical error");
    }
    return (React.createElement(CustomAuthenticator, { screensConfig: screensConfig, lang: lang, usernameIsEmailAddress: usernameIsEmailAddress, displayLogo: displayLogo, logo: logo, loader: Loader },
        React.createElement(AuthProvider, { ...props, displayLogo: displayLogo, logo: logo, loader: Loader }, children)));
};
const LocalAuthentifier = ({ fake = false, children, ...localAuthProps }) => fake ? (React.createElement(FakeAuthentifier, null, children)) : (React.createElement(RealLocalAuthentifier, { ...localAuthProps }, children));
export default LocalAuthentifier;
