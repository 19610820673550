import { useState, useEffect } from "react";
import { useUserProfile } from "..";
export const useUserInfo = () => {
    const userProfile = useUserProfile();
    const [user, setUser] = useState(null);
    useEffect(() => {
        const fetchUser = async () => {
            if (userProfile.initialUserInfo && userProfile.initialUserInfo.id) {
                const result = await userProfile.api.usersRead(userProfile.initialUserInfo.id);
                setUser(result);
            }
        };
        if (!user) {
            fetchUser();
        }
    }, [user, userProfile.initialUserInfo, userProfile.api]);
    return user;
};
