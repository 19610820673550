import React, { Suspense } from "react";
import { QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import queryClient from "./reactQueryConfig";
import { LocalAuthentifier } from "react-cognito-authentifier";
import "react-virtualized/styles.css";
import { ToastContainer, Slide } from "react-toastify";
import ENV_CONFIG from "env";
import Loader from "components/Loader";
import PublicContactPage from "components/PublicContactPage";
import AuthenticatedApp from "./AuthenticatedApp";
import passwordPolicy from "../passwordPolicy";
import i18n from "i18next";
import useAppConfiguration from "../hooks/useAppConfiguration";
import UserApi from "api/UserApi";
const AppLoader = () => React.createElement(Loader, { size: "small" });
export default () => {
    const { displayPublicContractPage } = useAppConfiguration();
    const userApi = new UserApi({}, ENV_CONFIG.SPEIRO_API_URL);
    return (React.createElement(Suspense, { fallback: React.createElement(AppLoader, null) },
        React.createElement(QueryClientProvider, { client: queryClient },
            React.createElement(Router, null,
                React.createElement(Routes, null,
                    displayPublicContractPage && (React.createElement(Route, { path: "/public/contact", element: React.createElement(PublicContactPage, null) })),
                    React.createElement(Route, { path: "*", element: React.createElement(LocalAuthentifier, { screensConfig: {
                                footer: () => React.createElement(React.Fragment, null),
                                signIn: {
                                    displayCover: true,
                                    logo: ENV_CONFIG.LOGO_LINK,
                                    cover: ENV_CONFIG.COVER_LINK,
                                    resendTempPassword: async (email) => {
                                        await userApi.resendTempPassword(email);
                                    }
                                },
                                forgotPassword: {
                                    displayCover: true,
                                    logo: ENV_CONFIG.LOGO_LINK,
                                    cover: ENV_CONFIG.COVER_LINK,
                                    passwordPolicy,
                                },
                                requireNewPassword: {
                                    passwordPolicy
                                }
                            }, awsConfig: {
                                identityPoolId: ENV_CONFIG.COGNITO.COGNITO_ID_POOL,
                                userPoolId: ENV_CONFIG.COGNITO.COGNITO_USER_POOL,
                                region: ENV_CONFIG.COGNITO.COGNITO_REGION,
                                userPoolWebClientId: ENV_CONFIG.COGNITO.COGNITO_CLIENT_ID
                            }, userApiConfig: {
                                host: ENV_CONFIG.COGNITO.USER_API_URL
                            }, displayLogo: false, lang: i18n.language, usernameIsEmailAddress: true, loader: AppLoader },
                            React.createElement(AuthenticatedApp, null)) })))),
        React.createElement(ToastContainer, { autoClose: 3000, hideProgressBar: true, transition: Slide })));
};
