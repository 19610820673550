import React from "react";
import styles from "./styles/confirmationPage.scss";
import Lottie from "react-lottie";
import animationData from "./animations/email.json";
import Navbar, { NavbarGroup, NavbarLogo } from "components/commons/Navbar";
import PageContainer from "components/commons/PageContainer";
import PageFooter from "components/PageFooter";
import { Button } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ENV_CONFIG from "env";
const ConfirmationPage = () => {
    const { t } = useTranslation();
    let navigate = useNavigate();
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (React.createElement("div", { className: styles.root },
        React.createElement(PageContainer, { footer: PageFooter },
            React.createElement(Navbar, { sticky: true },
                React.createElement(NavbarGroup, { to: "/" },
                    React.createElement(NavbarLogo, { image: ENV_CONFIG.LOGO_LINK }))),
            React.createElement("div", { className: styles.container },
                React.createElement("div", { className: styles.logo },
                    React.createElement(Lottie, { options: defaultOptions, height: 200, width: 200 })),
                React.createElement("div", { className: styles.text }, t(`contact.messages.has_been_sent`)),
                React.createElement("div", { className: styles.subText }, t(`contact.messages.response_soon`)),
                React.createElement("div", { className: styles.horizDivider }),
                React.createElement(Button, { variant: "contained", className: styles.button, onClick: () => {
                        navigate(-1);
                    } }, t(`contact.button_go_back`))))));
};
export default ConfirmationPage;
