import { useAuth } from "react-cognito-authentifier";
import ENV_CONFIG from "env";
import EndCustomerInfoApi from "../EndCustomerApi";
import { useMemo } from "react";
export function getEndCustomersApi(auth) {
    return new EndCustomerInfoApi({}, ENV_CONFIG.SPEIRO_API_URL, auth.idFetch);
}
export const useEndCustomersApi = () => {
    const auth = useAuth();
    return useMemo(() => new EndCustomerInfoApi({}, ENV_CONFIG.SPEIRO_API_URL, auth.idFetch), [auth.idFetch]);
};
export default useEndCustomersApi;
