import React, { useEffect, useState, useCallback } from "react";
import CustomScreen from "./CustomScreen";
import CustomSignedInScreen from "./CustomSignedInScreen";
import { Hub, Auth } from "aws-amplify";
import LoaderScreen from "../LoaderScreen";
const CustomAuthenticatorWithInitialState = ({ children, screensConfig, lang, usernameIsEmailAddress, initialSignedIn, }) => {
    const [auth, setAuth] = useState({
        state: initialSignedIn ? "signedIn" : "signIn",
    });
    useEffect(() => {
        const onAuthEvent = (data) => {
            switch (data.payload.event) {
                case "signIn":
                    setAuth({ state: "signedIn", data: data.payload.data });
                    break;
                case "signOut":
                    setAuth({ state: "signIn", data: data.payload.data });
                    break;
                case "forgotPassword":
                    setAuth({ state: "forgotPassword", data: data.payload.data });
                    break;
                case "forgotPasswordSubmit":
                    setAuth({
                        state: "signIn",
                        data: {
                            ...data.payload.data,
                            success: "forgot_password.password_changed",
                        },
                    });
                    break;
            }
        };
        Hub.listen("auth", onAuthEvent);
        return () => {
            Hub.remove("auth", onAuthEvent);
        };
    }, []);
    const onStateChange = useCallback((state, data) => {
        setAuth({ state, data });
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(CustomScreen, { screensConfig: screensConfig, usernameIsEmailAddress: usernameIsEmailAddress, authState: auth.state, authData: auth.data, onStateChange: onStateChange, lang: lang }),
        React.createElement(CustomSignedInScreen, { authState: auth.state }, children)));
};
const CustomAuthenticator = ({ displayLogo, logo, loader: Loader, ...props }) => {
    const [initialSignedIn, setInitialSignedIn] = useState();
    useEffect(() => {
        Auth.currentSession().then(() => {
            setInitialSignedIn(true);
        }, () => {
            setInitialSignedIn(false);
        });
    }, []);
    if (initialSignedIn === undefined) {
        return Loader ? (React.createElement(Loader, null)) : (React.createElement(LoaderScreen, { text: "Retrieving user session", displayLogo: displayLogo, logo: logo }));
    }
    return (React.createElement(CustomAuthenticatorWithInitialState, { ...props, initialSignedIn: initialSignedIn }));
};
export default CustomAuthenticator;
