import { useMemo } from "react";
import useAppContext from "../components/AppContextProvider/hooks/useAppContext";
import { endCustomerContractForEndCustomerQuery } from "queries/fetchContractsByEndCustomerId";
import { useSuspenseQuery } from "@tanstack/react-query";
import noQuery from "queries/noQuery";
import { useAuth } from "react-cognito-authentifier";
const defaultState = {
    contactName: "",
    contactEmail: "",
    contactPhoneNumber: "",
    termsAndConditions: "",
    licenseAgreement: "",
    privacyPolicy: ""
};
function getVersion(contract) {
    if (!contract || !contract.contract.dataConfiguration)
        return defaultState;
    const { contactName, contactEmail, contactPhoneNumber, termsAndConditions, licenseAgreement, privacyPolicy, } = contract.contract.dataConfiguration;
    if (!contactEmail || !contactName || !contactPhoneNumber)
        return defaultState;
    return {
        contactEmail,
        contactName,
        contactPhoneNumber,
        termsAndConditions,
        licenseAgreement,
        privacyPolicy
    };
}
function useVersion() {
    const { userManager } = useAppContext();
    const endCustomer = userManager.getEndCustomer();
    const auth = useAuth();
    const { data: contract } = useSuspenseQuery(endCustomer
        ? endCustomerContractForEndCustomerQuery(auth, endCustomer.id)
        : noQuery);
    const version = useMemo(() => getVersion(contract), [contract]);
    return version;
}
export default useVersion;
