import React from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIosRounded";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styles from "./backLink.scss";
const BackLink = ({ to }) => {
    const { t } = useTranslation();
    let navigate = useNavigate();
    const handleBackButton = () => {
        if (to) {
            navigate(to, { replace: true });
        }
        else {
            navigate(-1);
        }
    };
    return (React.createElement("div", { role: "button", onClick: handleBackButton, className: styles.root },
        React.createElement(ArrowBackIcon, { className: styles.icon }),
        React.createElement("div", { className: styles.text }, t("navigation.back"))));
};
export default BackLink;
