// The following code is adapted from https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
// Note(Julien): I wish I didn't have to implement Cognito password verification by hand but I couldn't find any library which does what we need
const isUppercase = (c) => /[A-Z]/.test(c);
const isLowercase = (c) => /[a-z]/.test(c);
const isNumber = (c) => /[0-9]/.test(c);
const isSymbol = (c) => "^$*.[]{}()?\"!@#%&/\\,><':;|_~`".includes(c);
const isAllowed = (c) => isUppercase(c) ||
    isLowercase(c) ||
    isNumber(c) ||
    isSymbol(c) ||
    "+=".includes(c);
const checkPassword = (password, policy) => {
    const errors = new Set();
    for (const c of password) {
        if (!isAllowed(c)) {
            errors.add("hasForbiddenCharacters");
            break;
        }
    }
    if (!policy) {
        return errors.size > 0 ? errors : null;
    }
    if (password.length < policy.minimumLength) {
        errors.add("hasTooFewCharacters");
    }
    if (policy.requireLowercase) {
        if (!/[a-z]/.test(password)) {
            errors.add("hasNoLowercase");
        }
    }
    if (policy.requireUppercase) {
        if (!/[A-Z]/.test(password)) {
            errors.add("hasNoUppercase");
        }
    }
    if (policy.requireNumbers) {
        if (!/[0-9]/.test(password)) {
            errors.add("hasNoNumber");
        }
    }
    if (policy.requireSymbols) {
        let good = false;
        for (const c of password) {
            if (isSymbol(c)) {
                good = true;
                break;
            }
        }
        if (!good) {
            errors.add("hasNoSymbol");
        }
    }
    return errors.size > 0 ? errors : null;
};
export default checkPassword;
