import React from "react";
import classNames from "classnames";
import FieldErrorMessage from "../FieldErrorMessage";
import styles from "./styles/formTextInput.scss";
const FormTextareaInput = ({ label, fieldName, errors, formRef }) => (React.createElement("div", { className: styles.root },
    React.createElement("label", { className: styles.root__label, htmlFor: fieldName }, label),
    React.createElement("textarea", { ref: formRef, name: fieldName, className: classNames(styles.textarea, {
            [styles.errorInput]: fieldName in errors
        }), id: fieldName, rows: 10 }),
    React.createElement(FieldErrorMessage, { errors: errors, name: fieldName })));
export default FormTextareaInput;
