import React from "react";
import Select from "@material-ui/core/Select";
import { ListItemIcon, MenuItem, ListItemText } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import styles from "./styles/LanguageSelector.scss";
export default ({ langs = [], displayText = false, style }) => {
    const { i18n } = useTranslation();
    const lang = i18n.language;
    const onSelectChange = (evt) => {
        const lng = langs.find(l => l.key === evt.target.value);
        if (lng) {
            i18n.changeLanguage(evt.target.value);
        }
    };
    return (React.createElement(Select, { "data-testid": "select-language-selector", disableUnderline: true, value: lang, style: style, classes: { root: styles.select }, onChange: onSelectChange }, langs.map(l => (React.createElement(MenuItem, { "data-testid": `option-language-${l.key}`, key: l.key, value: l.key, className: styles.item },
        React.createElement(ListItemIcon, { classes: { root: styles.iconContainer } },
            React.createElement("img", { className: styles.icon, alt: "logo", src: l.flagUrl })),
        displayText && (React.createElement(ListItemText, null, l.key.toLocaleUpperCase())))))));
};
export { default as NavbarLogo } from "./NavbarLogo";
