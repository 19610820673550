import React from "react";
import { useLocation, Link } from "react-router-dom";
import classNames from "classnames";
import styles from "./subNavBarLink.scss";
const SubNavBarLink = ({ name, to, onClick = () => { } }) => {
    const location = useLocation();
    const isCurrentLocation = location.pathname + location.hash === to;
    return (React.createElement(Link, { className: classNames(styles.root, {
            [styles.selected]: isCurrentLocation
        }), to: to, onClick: onClick }, name));
};
export default SubNavBarLink;
