import { BaseAPI } from "./BaseApi";
import { apiGet, apiPost, apiPatch } from "./helpers";
import urljoin from "url-join";
import { toQueryString } from "utils/api";
class ManagerApi extends BaseAPI {
    getManagers = async (queryOptions = {}) => {
        const { basePath, fetch: customFetch } = this;
        const query = Object.keys(queryOptions).length
            ? toQueryString(queryOptions)
            : "";
        return apiGet(urljoin(basePath, "/manager", query), customFetch);
    };
    getManager = async (id, options = {}) => {
        const { basePath, fetch: customFetch } = this;
        return apiGet(urljoin(basePath, "manager", encodeURIComponent(id)), customFetch, options);
    };
    getManagerByUserId = async (userId, options = {}) => {
        if (!userId)
            return undefined;
        const { basePath, fetch: customFetch } = this;
        return apiGet(urljoin(basePath, "manager", "userId", encodeURIComponent(userId)), customFetch, options);
    };
    createManager = async (data, options = {}) => {
        return apiPost(urljoin(this.basePath, "manager"), this.fetch, data, options);
    };
    updateManager = async (id, data, options = {}) => {
        return apiPatch(urljoin(this.basePath, "manager", encodeURIComponent(id)), this.fetch, data, options);
    };
    activateManager = async (id) => {
        const { basePath, fetch: customFetch } = this;
        return apiPost(urljoin(basePath, "manager", encodeURIComponent(id), "activate"), customFetch);
    };
    deactivateManager = async (id) => {
        const { basePath, fetch: customFetch } = this;
        return apiPost(urljoin(basePath, "manager", encodeURIComponent(id), "deactivate"), customFetch);
    };
}
export default ManagerApi;
