import { QueryCache, QueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 3,
            refetchOnWindowFocus: false,
            retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
        },
    },
    queryCache: new QueryCache({
        onError: (_error, query) => {
            if (query.meta?.errorMessage) {
                toast.error(query.meta.errorMessage);
            }
        },
    }),
});
export default queryClient;
