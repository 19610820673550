import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import Axalogo from "./commons/AxaLogo";
const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        right: 0,
        bottom: 0,
        height: "100%",
        width: "100%",
    },
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
    },
    logoContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    progressContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 70,
    },
    progress: {
        color: "#00008F",
    },
    text: {
        marginTop: 30,
        color: "#00008F",
        textTransform: "uppercase",
        fontWeight: "bold",
    },
}));
const LoaderScreen = ({ text, displayLogo = true, logo }) => {
    const classes = useStyles({});
    return (React.createElement("div", { className: classes.root },
        React.createElement("div", { className: classes.container },
            React.createElement("div", { className: classes.logoContainer }, displayLogo ? logo || React.createElement(Axalogo, { width: 100, height: 100 }) : null),
            React.createElement("div", { className: classes.progressContainer },
                React.createElement(CircularProgress, { className: classes.progress })),
            text && React.createElement("div", { className: classes.text }, text))));
};
export default LoaderScreen;
