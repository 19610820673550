import React, { useMemo } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthContext, buildAuth } from "./AuthProvider";
import { UserProfileContext } from "./UserProvider";
const notImplementedFn = () => {
    throw new Error("Function not implemented");
};
const fakeAuth = buildAuth(notImplementedFn);
const defaultGroups = ["sample-group"];
const getFakeUserProfile = (groups) => {
    const sampleUserInfo = {
        first_name: "John",
        last_name: "Doe",
        username: "example@axa.com",
        email: "example@axa.com",
        phone_number: "+33123456789",
        groups,
    };
    const usersRead = (id) => {
        if (id === "sample-user-id") {
            return Promise.resolve(sampleUserInfo);
        }
        throw new Error("Calling usersRead with an id other than one's own not implemented");
    };
    return {
        api: {
            usersRead,
            usersCreate: notImplementedFn,
            usersSearch: notImplementedFn,
            usersList: notImplementedFn,
            usersPartialUpdate: notImplementedFn,
            usersUpdate: notImplementedFn,
        },
        initialUserInfo: {
            id: "sample-user-id",
            email: "example@axa.com",
            phone_number: "+33123456789",
            username: "example@axa.com",
            groups,
        },
    };
};
const useFakeUserProfile = (groups) => useMemo(() => getFakeUserProfile(groups), [groups]);
const FakeAuthentifier = ({ children, groups = defaultGroups, }) => {
    const fakeUserProfile = useFakeUserProfile(groups);
    return (React.createElement(Router, null,
        React.createElement(AuthContext.Provider, { value: fakeAuth },
            React.createElement(UserProfileContext.Provider, { value: fakeUserProfile }, children))));
};
export default FakeAuthentifier;
