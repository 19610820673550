import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { setDefaultLocale, registerLocale } from "react-datepicker";
import moment from "moment";
import numeral from "numeral";
import { capitalizeFirstLetter } from "utils/strings";
import countries from "i18n-iso-countries";
import { enGB, ro, fr, hu } from "date-fns/locale";
import enTranslation from "static/locales/en/translation.json";
import roTranslation from "static/locales/ro/translation.json";
import frTranslation from "../static/locales/fr/translation.json";
import huTranslation from "../static/locales/hu/translation.json";
import { setLocale } from "yup";
const enLocale = require("i18n-iso-countries/langs/en.json");
const frLocale = require("i18n-iso-countries/langs/fr.json");
const roLocale = require("i18n-iso-countries/langs/ro.json");
const huLocale = require("i18n-iso-countries/langs/hu.json");
const browserLangDetector = {
    name: "navigator-custom",
    lookup: () => {
        const { language } = navigator;
        if (!language) {
            return undefined;
        }
        if (language.startsWith("hu")) {
            return "hu";
        }
        if (language.startsWith("ro")) {
            return "ro";
        }
        if (language.startsWith("en")) {
            return "en";
        }
        if (language.startsWith("fr")) {
            return "fr";
        }
        return undefined;
    }
};
const languageDetector = new LanguageDetector();
languageDetector.addDetector(browserLangDetector);
const initI18n = () => {
    countries.registerLocale(enLocale);
    countries.registerLocale(roLocale);
    countries.registerLocale(frLocale);
    countries.registerLocale(huLocale);
    // For react datepicker
    registerLocale("fr", fr);
    registerLocale("en", enGB);
    registerLocale("ro", ro);
    registerLocale("hu", hu);
    numeral.register("locale", "eur", {
        delimiters: {
            thousands: " ",
            decimal: ","
        },
        abbreviations: {
            thousand: "k",
            million: "m",
            billion: "b",
            trillion: "t"
        },
        ordinal(number) {
            return number === 1 ? "er" : "ème";
        },
        currency: {
            symbol: "€"
        }
    });
    numeral.locale("eur");
    i18n
        // load translation using xhr -> see /public/locales
        // learn more: https://github.com/i18next/i18next-xhr-backend
        .use(Backend)
        // detect user language
        // learn more: https://github.com/i18next/i18next-browser-languageDetector
        .use(languageDetector)
        // pass the i18n instance to react-i18next.
        .use(initReactI18next)
        // init i18next
        // for all options read: https://www.i18next.com/overview/configuration-options
        .init({
        fallbackLng: "fr",
        debug: true,
        interpolation: {
            format(value, format) {
                if (format === "uppercase")
                    return value.toUpperCase();
                if (format === "capitalize")
                    return capitalizeFirstLetter(value);
                if (format === "eur")
                    return numeral(value).format("0,0[.]00 $");
                if (value instanceof Date || value instanceof moment)
                    return moment(value).format(format);
                return value;
            },
            escapeValue: false // not needed for react as it escapes by default
        },
        // Explicit declaration of resources is needed for jest tests to pass
        resources: {
            en: {
                translation: enTranslation
            },
            ro: {
                translation: roTranslation
            },
            fr: {
                translation: frTranslation
            },
            hu: {
                translation: huTranslation
            }
        },
        detection: {
            order: ["localStorage", "navigator-custom"],
            caches: ["localStorage"]
        }
    });
    // Initiate langage setting for MomentJS and React Datepicker
    moment.locale(i18n.language);
    setDefaultLocale(i18n.language);
    i18n.on("languageChanged", lng => {
        moment.locale(lng);
        setLocale({
            // Arrow function is required to get the message updated
            // on language change
            mixed: {
                required: () => i18n.t("yup.required")
            }
        });
    });
};
export default initI18n;
