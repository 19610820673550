import React, { useState } from "react";
import { Auth } from "aws-amplify";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import PasswordInput from "../../commons/PasswordInput";
import styles from "./styles/SigninScreen.scss";
const useMessage = (authData) => {
    const initialMessage = authData && authData.success
        ? { type: "success", content: authData.success }
        : null;
    return useState(initialMessage);
};
const getInitUsername = () => {
    const params = queryString.parse(window.location.search);
    return params.email || "";
};
const getErrorMessage = (t, message) => {
    if (["NotAuthorizedException", "UserNotFoundException"].includes(message.code ?? "")) {
        return t("signin.incorrect_username_or_password");
    }
    return message.content;
};
const SigninScreen = ({ config, onStateChange, usernameIsEmailAddress, authData, }) => {
    const [username, setUsername] = useState(getInitUsername());
    const [password, setPassword] = useState("");
    const [message, setMessage] = useMessage(authData);
    const { t } = useTranslation();
    const contactUsHref = config?.contactUsHref;
    const resendTempPassword = config?.resendTempPassword;
    const signIn = async (event) => {
        setMessage(null);
        event.preventDefault();
        const param = {
            username,
            password,
        };
        try {
            const user = await Auth.signIn(param);
            if (user.challengeName &&
                user.challengeName === "NEW_PASSWORD_REQUIRED") {
                onStateChange("requireNewPassword", user);
            }
        }
        catch (err) {
            setMessage({
                type: "error",
                code: err.code,
                content: t("signin.incorrect_username_or_password"),
            });
        }
    };
    const forgotPassword = () => {
        Auth.forgotPassword(username).catch((err) => {
            if (resendTempPassword &&
                err.code === "NotAuthorizedException" &&
                err.message.match(/User password cannot be reset in the current state/)) {
                setMessage({
                    type: "success",
                    content: t("forgot_password.force_change_password"),
                });
                resendTempPassword(username);
            }
            else {
                setMessage({
                    type: "error",
                    content: t("signin.incorrect_username_or_password"),
                });
            }
        });
    };
    const onUsernameChange = (evt) => {
        setUsername(evt.target.value);
    };
    const onPasswordChange = (evt) => {
        setPassword(evt.target.value);
    };
    return (React.createElement("form", { className: styles.formContainer, onSubmit: signIn },
        React.createElement("div", { className: styles.title }, t("signin.title")),
        message &&
            (message.type === "error" ? (React.createElement("div", { className: styles.errorContainer }, t("signin.fail", { error: getErrorMessage(t, message) }))) : (React.createElement("div", { className: styles.successContainer }, t(message.content)))),
        React.createElement("label", { className: styles.label, htmlFor: "username" }, t(usernameIsEmailAddress ? "signin.email_address" : "signin.username")),
        React.createElement("input", { className: styles.input, onChange: onUsernameChange, value: username, type: "text", id: "username", key: "username", name: "username", placeholder: "" }),
        React.createElement("div", { className: styles.passwordLabelContainer },
            React.createElement("label", { className: styles.label, htmlFor: "password" }, t("signin.password")),
            username && (React.createElement("span", { className: styles.forgotPassword, onClick: forgotPassword }, t("signin.forgot_password")))),
        React.createElement(PasswordInput, { onChange: onPasswordChange, name: "password" }),
        React.createElement("p", null),
        React.createElement("button", { type: "submit", className: styles.button, disabled: !username || !password }, t("signin.sign_in")),
        contactUsHref && (React.createElement("p", { className: styles.usernameNotRecognized },
            t(usernameIsEmailAddress
                ? "signin.email_not_recognized"
                : "signin.username_not_recognized"),
            " ",
            React.createElement("a", { href: contactUsHref }, t("signin.contact_us"))))));
};
export default SigninScreen;
