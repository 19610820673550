import UserGroupsKeys from "./userGroupsKeys";
export default class UserManager {
    user;
    endCustomer;
    promoter;
    manager;
    userGroupsKeys = new UserGroupsKeys();
    isDefinedUser = () => this.user !== undefined && this.user;
    getUser = () => {
        return this.user;
    };
    setEndCustomer = (endCustomer) => {
        this.endCustomer = endCustomer;
    };
    setPromoter = (promoter) => {
        this.promoter = promoter;
    };
    setManager = (manager) => {
        this.manager = manager;
    };
    getEndCustomer = () => {
        return this.endCustomer;
    };
    getPromoter = () => {
        return this.promoter;
    };
    getManager = () => {
        return this.manager;
    };
    isRegisteredFarmer = () => {
        return !!this.endCustomer && this.endCustomer.isUserRegistered;
    };
    hasAcceptedPlatformLicenceAgreement = () => {
        return this.endCustomer?.platformLicenceAgreement;
    };
    isAdmin = () => {
        return !!this.user && this.user.groups.includes(this.userGroupsKeys.admin);
    };
    isMarketing = () => {
        return (!!this.user && this.user.groups.includes(this.userGroupsKeys.marketing));
    };
    isPromoter = () => {
        return (!!this.user && this.user.groups.includes(this.userGroupsKeys.promoter));
    };
    isFarmer = () => {
        return !!this.user && this.user.groups.includes(this.userGroupsKeys.farmer);
    };
    isSuperFarmer = () => {
        return !!this.user && this.user.groups.includes(this.userGroupsKeys.super_farmer);
    };
    constructor(user, endCustomer) {
        if (user) {
            this.user = {
                first_name: "",
                last_name: "",
                username: user.username,
                email: user.email,
                phone_number: user.phone_number,
                picture: undefined,
                date_joined: new Date(),
                last_login: new Date(),
                last_modification: new Date(),
                is_active: false,
                groups: user.groups
            };
        }
        this.endCustomer = endCustomer;
    }
}
