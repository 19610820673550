import React, { useState } from "react";
import { useLocation, Link } from "react-router-dom";
import classNames from "classnames";
import styles from "./SubNavBarDropDown.scss";
import DropDown from "./DropDown";
const SubNavBarDropDown = ({ name, to, options }) => {
    const [dropdown, setDropdown] = useState(false);
    const onMouseEnter = () => {
        setDropdown(true);
    };
    const onMouseLeave = () => {
        setDropdown(false);
    };
    const location = useLocation();
    const isCurrentLocation = location.pathname === to;
    return (React.createElement("div", { onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave },
        React.createElement(Link, { className: classNames(styles.root, {
                [styles.selected]: isCurrentLocation
            }), to: to }, name),
        dropdown && React.createElement(DropDown, { MenuItems: options })));
};
export default SubNavBarDropDown;
